import {
  MdAttachMoney,
  MdDataUsage,
  MdThumbUp,
  MdTrendingUp,
} from "react-icons/md";

export const AiAgentAssistContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "AI Agent Assist is an advanced AI-powered solution designed to help businesses manage customer interactions more efficiently. By automating routine tasks, offering real-time support to human agents, and providing actionable insights, it enhances agent productivity and boosts customer satisfaction. With AI Agent Assist, businesses can effectively scale their operations, meet evolving customer expectations, and deliver exceptional service—all while reducing costs and improving operational efficiency. ",
    imageURL: "/images/products/agent-assist/banner.png", // Updated image path
    sectionTile: "",
    title: "Revolutionize Customer Service with AI Agent Assist", // Updated banner title
  },
  stats: {
    title: "Empower Customer Engagement with AI",
    description:
      "Transforming service efficiency and loyalty through intelligent automation",

    content: [
      {
        id: "1",
        count: 96,
        title:
          "of customers say customer service is critical for brand loyalty.",
        source: "Microsoft",
      },
      {
        id: "2",
        count: 40,
        title: "reduction in operational costs with AI automation.",
        source: "McKinsey",
      },
      {
        id: "3",
        count: 89,
        title: "of customers prefer AI support for loyalty.",
        source: "Salesforce",
      },
      {
        id: "4",
        count: 75,
        title: "of interactions will be AI-driven by 2026.",
        source: "Gartner",
      },
    ],
  },
  valueProps: [
    {
      id: "1",
      imageURL: "/images/products/agent-assist/enhancing-interactions.png", // Updated image path
      pointer: "/images/icons/pointer.svg",
      title: "Enhancing Customer Interactions",
      mainDescription:
        "AI Agent Assist is specifically designed to tackle the challenges businesses and human agents face in managing customer interactions. This solution provides a range of AI-driven features that significantly boost agent efficiency, improve customer satisfaction, and enable businesses to scale their operations effectively.",
    },
    {
      id: "2",
      imageURL: "/images/products/agent-assist/conversation-analytics.png", // Updated image path
      pointer: "/images/icons/pointer.svg",
      title: "Unified Conversation History and Analytics",
      mainDescription:
        "A key feature of AI Agent Assist is its ability to provide agents with a comprehensive view of customer interactions across multiple channels, such as email, phone, and chat. Additionally, the platform offers robust analytics capabilities, empowering agents to track conversation patterns and performance metrics to facilitate continuous improvement in service quality.",
    },
    {
      id: "3",
      imageURL: "/images/products/agent-assist/data-masking.png", // Updated image path
      pointer: "/images/icons/pointer.svg",
      title: "Protecting Customer Privacy with PII Data Masking",
      mainDescription:
        "Our PII Data Masking solution ensures that sensitive information remains confidential during customer interactions. By anonymizing personally identifiable information, businesses can enhance customer privacy while maintaining high-quality service. This technology not only fosters trust but also ensures compliance with regulatory standards.",
    },
    {
      id: "4",
      imageURL: "/images/products/agent-assist/real-time-support.png", // Updated image path
      pointer: "/images/icons/pointer.svg",
      title: "Real-Time Support and Summarization",
      mainDescription:
        "AI Agent Assist enhances the handling of complex customer queries by offering live agent support through real-time conversation summarization. This feature extracts key details from customer interactions, enabling agents to quickly grasp the context of inquiries.",
    },
    {
      id: "5",
      imageURL: "/images/products/agent-assist/sentiment-multilingual.png", // Updated image path
      pointer: "/images/icons/pointer.svg",
      title: "Sentiment Analysis and Multilingual Support",
      mainDescription:
        "The platform leverages AI-powered sentiment analysis to detect the emotional tone of customer interactions. This allows agents to prioritize and tailor their responses. Furthermore, AI Agent Assist offers seamless translation features, enabling agents to communicate effectively with customers in various languages.",
    },
  ],
  whyChooseUs: {
    title: "Why Choose AI Agent Assist?",

    content: [
      {
        id: "1",
        title: "Improved Agent Productivity",
        tagline: "Empower Agents to Focus on What Matters Most ",
        icon: <MdTrendingUp size={20} />, // Icon for productivity
      },
      {
        id: "2",
        title: "Enhanced Customer Satisfaction",
        tagline: "Deliver Fast, Personalized Support ",
        icon: <MdThumbUp size={20} />, // Icon for satisfaction
      },
      {
        id: "3",
        title: "Cost Reduction",
        tagline: "Achieve More with Less ",
        icon: <MdAttachMoney size={20} />, // Icon for cost
      },
      {
        id: "4",
        title: "Data-Driven Decision Making",
        tagline: "Leverage Insights to Optimize Performance ",
        icon: <MdDataUsage size={20} />, // Icon for data
      },
    ],
  },
  demoVideo: {
    title: "Experience AI Agent Assist in Action",
    description:
      "Discover how AI Agent Assist elevates customer interactions through real-time AI solutions.",
    videoURL: "https://www.youtube.com/embed/8XvF9gZJ0PQ", // Example video link
  },
  FAQ: [
    {
      id: "1",
      question: "How does AI Agent Assist improve agent productivity?",
      answer:
        "AI Agent Assist enhances agent productivity by automating repetitive tasks and providing instant access to information. Agents can focus on more complex customer queries, reducing workload and improving response times.",
    },
    {
      id: "2",
      question: "What role does AI play in enhancing customer satisfaction?",
      answer:
        "AI delivers faster, more accurate responses and provides personalized support through sentiment analysis, leading to improved customer satisfaction and higher loyalty.",
    },
    {
      id: "3",
      question: "Can businesses scale operations with AI Agent Assist?",
      answer:
        "Yes, AI Agent Assist allows businesses to scale their customer support without needing additional staff by automating routine tasks and equipping agents with advanced tools.",
    },
    {
      id: "4",
      question: "How does AI Agent Assist support data-driven decision-making?",
      answer:
        "AI Agent Assist offers insights and analytics that help track performance, customer satisfaction, and conversation trends, enabling data-driven decisions to improve service quality.",
    },
  ],
  meta: {
    title: "AI Agent Assist | iNextLabs",
    description:
      "Transform customer interactions with AI-powered solutions. AI Agent Assist automates routine tasks, improves agent efficiency, and enhances customer satisfaction through cutting-edge AI technologies.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/ai-agent-assist.png", // Updated meta image
    type: "website",
  },
};

import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import Footer from "../footer/Footer";
import Meta from "../Meta";

const TermsOfService = () => {
  return (
    <div>
      <Meta
        title="iNextLabs - Terms of Services"
        description="Using this website, we assume that you accept our terms and conditions. These terms and conditions apply to all users, visitors, and individuals who access our site."
        noindex={false}
      />

      <Header />
      <Filler />

      <div className="outerContainer">
        <div className="innerContainer left">
          <h1 className="bannerTitle">Terms of Service</h1>
          <p className="para">
            This agreement ("Agreement") is between the customer or the entity
            the customer represents ("Customer") and iNextLabs Pte Ltd
            ("iNextLabs"). Both iNextLabs and the Customer may be individually
            referred to as a “Party” and collectively as the “Parties.” By using
            iNextLabs' services, the Customer agrees to the terms outlined in
            this Agreement.
          </p>
          <br />
          <h3 className="paragraphTitle">1.Definitions and Interpretation </h3>
          <p className="para">Certain terms are defined as follows:</p>
          <ol>
            <li className="para">
              <p>
                <b>Agreement: </b> refers to this document and any annexures or
                amendments.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Applicable Laws: </b> refers to any relevant legal
                regulations. "Cloud" refers to cloud services provided by
                platforms like Microsoft Azure or Google Cloud.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Confidential Information: </b> includes any proprietary or
                sensitive data exchanged between the Parties.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Charges: </b>refers to the payments agreed upon for services
                provided.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Data Controller: </b>refers to the entity that controls data,
                while "Data Processor" refers to the one that processes it.
              </p>
            </li>
          </ol>
          <h3 className="paragraphTitle">2. Scope of Services</h3>
          <p className="para">
            Subject to payment, iNextLabs will provide access to its AI-powered
            solutions via its website, maintaining the latest versions of the
            software. The Customer is responsible for associated costs as
            outlined in the annexure. iNextLabs will deliver the services in a
            SaaS model, accessible via the internet.
          </p>
          <br />

          <h3 className="paragraphTitle">3. Payment and Pricing</h3>
          <p className="para">
            The Customer may subscribe to services on a monthly or yearly basis.
            Payment is by credit card, and subscriptions automatically renew
            unless canceled seven days prior to renewal. iNextLabs reserves the
            right to adjust pricing based on changes made by third-party
            providers. No refunds will be issued unless specifically agreed upon
            by iNextLabs.
          </p>
          <br />
          <h3 className="paragraphTitle">4. Data Privacy</h3>
          <p className="para">
            The Parties agree that personal data may be exchanged during service
            provision. Both iNextLabs and the Customer are required to comply
            with applicable data protection laws, including the Personal Data
            Protection Act 2012 (PDPA). iNextLabs will only process data as
            instructed by the Customer and will take measures to ensure data
            security.
          </p>
          <ol>
            <li className="para">
              <p>
                <b>Agreement: </b> shall mean this Agreement in its entirety,
                including all the annexure attached to or referenced to this
                Agreement and any other document pursuant to this Agreement and
                shall include any amendment(s), addendum(s), annexure(s)
                thereto, from time to time.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Payment: </b> Payments are due in advance of each
                subscription period. iNextLabs reserves the right to suspend
                services if payment is not received by the due date. All fees
                are non-refundable unless stated otherwise.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Upgrades and Downgrades: </b> Customers can change their
                subscription plan at any time. Changes take effect at the next
                billing cycle unless otherwise agreed. Upgrading may incur
                additional fees, while downgrading may result in a loss of
                features.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Termination: </b>Either party can terminate the subscription
                at any time with written notice. If the customer terminates,
                access to services continues until the end of the current
                billing period. If iNextLabs terminates for reasons other than a
                breach of terms, the customer is entitled to a pro-rata refund.{" "}
              </p>
            </li>
            <li className="para">
              <p>
                <b>Early Termination: </b>Early termination fees may apply as
                outlined in the subscription plan to compensate iNextLabs for
                the loss of anticipated revenue.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Changes to Subscription Plans: </b>iNextLabs reserves the
                right to modify its subscription plans. Customers will be
                notified of any material changes at least 30 days in advance.
              </p>
            </li>
          </ol>
          <br />
          <h3 className="paragraphTitle">
            5.Indemnity and Limitation of Liability
          </h3>
          <p className="para">
            Each Party agrees to indemnify the other for any third-party claims
            or damages arising from breaches of the Agreement or negligence.
            iNextLabs' liability is limited to the charges paid during the
            Customer's last billing cycle. Neither Party is liable for indirect
            or consequential damages, such as loss of profits or business
            interruption.
          </p>
          <br />
          <h3 className="paragraphTitle">6.Warranties</h3>
          <p className="para">
            iNextLabs provides its services on an "as-is" basis without any
            express or implied warranties. There is no guarantee that services
            will be uninterrupted, secure, or error-free. The Customer uses the
            services at their own risk, and iNextLabs is not responsible for any
            damage resulting from the use of its services.
          </p>
          <br />
          <h3 className="paragraphTitle">7.Confidentiality</h3>
          <p className="para">
            Both Parties agree to keep all confidential information private and
            not disclose it to third parties, except as required by law or for
            internal purposes under similar confidentiality obligations.
            Confidentiality does not apply to publicly known information or
            information independently developed by the receiving party.
          </p>
          <br />
          <h3 className="paragraphTitle">8.Intellectual Property Rights</h3>
          <p className="para">
            iNextLabs retains all rights to its solutions and intellectual
            property. The Customer is granted a non-exclusive, non-transferable
            license to use the services under the terms of this Agreement. The
            Customer may not reverse-engineer, copy, or modify iNextLabs'
            software or transfer it to third parties without permission.
          </p>
          <br />
          <h3 className="paragraphTitle">9.Information Security</h3>
          <p className="para">
            iNextLabs will take reasonable measures to secure data against
            unauthorized access or destruction. While iNextLabs implements
            robust security protocols, no system is fully secure, and the
            Customer acknowledges the risks associated with transmitting data
            over the internet.
          </p>
          <br />
          <h3 className="paragraphTitle">10.Term and Termination</h3>
          <p className="para">
            This Agreement remains in effect for the duration of the
            subscription and renews automatically unless terminated. Either
            Party may terminate with one month’s notice. Upon termination, the
            Customer must return any confidential information and uninstall
            iNextLabs' software.
          </p>
          <br />
          <h3 className="paragraphTitle">
            11.Governing Law and Dispute Resolution
          </h3>
          <p className="para">
            This Agreement is governed by Singaporean law. In the event of a
            dispute, the Parties will first attempt to resolve it amicably.
            Failing that, disputes will be settled by arbitration under the
            rules of the Singapore International Arbitration Centre (SIAC), with
            arbitration proceedings held in Singapore.
          </p>
          <br />
          <h3 className="paragraphTitle">12.Miscellaneous</h3>
          <p className="para">
            No Party will be held liable for delays or failures due to force
            majeure events beyond reasonable control. Notices between the
            Parties must be in writing, and the Agreement can only be modified
            with the written consent of both Parties. This Agreement constitutes
            the entire understanding between the Parties and supersedes any
            prior agreements.
          </p>
          <br />
          <h1 className="bannerTitle">Terms of Use</h1>
          <p className="para">
            These Terms of Use govern the customer's use of iNextLabs'
            generative AI solutions. By accessing or using these services,
            customers agree to abide by the terms outlined herein.
          </p>
          <p className="para">
            These terms cover various aspects, including data ownership, hosting
            location, confidentiality, subscription terms, service level
            commitments, and backup availability. iNextLabs is committed to
            ensuring the security and confidentiality of customer data, offering
            reliable services through a subscription-based model, and adhering
            to strict performance standards.{" "}
          </p>
          <br />
          <h3 className="paragraphTitle">1. Data Ownership </h3>
          <p className="para">
            iNextLabs acknowledges that all data created, uploaded, or otherwise
            generated by the customer while using iNextLabs’ generative-AI
            solutions shall remain the sole property of the customer. iNextLabs
            will not claim any ownership rights or interest in such customer
            data. The customer retains full control over their data, including
            the rights to access, modify, delete, and transfer it at their
            discretion. iNextLabs will only access, use, or disclose customer
            data as necessary to perform services, comply with legal
            obligations, or with the customer's express written consent.
          </p>
          <br />

          <h3 className="paragraphTitle">2. Hosting Location</h3>
          <p className="para">
            iNextLabs provides its generative-AI solutions in a
            Software-as-a-Service (SaaS) environment. The hosting location of
            the customer's data will be determined based on the customer’s
            geographic location or other relevant factors, such as service
            availability, performance, and compliance with local data protection
            laws. iNextLabs ensures that the selected data center meets all
            standards for security, reliability, and legal compliance. The
            customer will be informed of the initial hosting location, with
            advance notice provided for any subsequent changes.
          </p>
          <br />

          <h3 className="paragraphTitle">3. Confidentiality and Security</h3>
          <p className="para">
            iNextLabs offers its generative-AI solutions through a
            subscription-based model. By subscribing, the customer agrees to the
            terms and conditions, including payment of fees, subscription
            duration, and renewal terms.
          </p>
          <br />
          <h3 className="paragraphTitle">4. Subscription</h3>
          <p className="para">
            iNextLabs is committed to maintaining the confidentiality and
            security of all customer data. It implements comprehensive
            administrative, technical, and physical safeguards to protect
            customer data from unauthorized access, use, alteration, or
            disclosure. Data is encrypted both in transit and at rest, and
            access is restricted to authorized personnel only, following the
            principle of least privilege. iNextLabs agrees to treat all customer
            data as confidential, disclosing it only as necessary to provide
            services, comply with legal obligations, or with the customer's
            consent. In case of a data breach, iNextLabs will promptly notify
            the affected customer and take measures to mitigate potential harm.
            iNextLabs complies with industry standards and regulations to ensure
            the security and confidentiality of customer data.
          </p>
          <ol>
            <li className="para">
              <p>
                <b>Agreement: </b> shall mean this Agreement in its entirety,
                including all the annexure attached to or referenced to this
                Agreement and any other document pursuant to this Agreement and
                shall include any amendment(s), addendum(s), annexure(s)
                thereto, from time to time.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Payment: </b> Payments are due in advance of each
                subscription period. iNextLabs reserves the right to suspend
                services if payment is not received by the due date. All fees
                are non-refundable unless stated otherwise.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Upgrades and Downgrades: </b> Customers can change their
                subscription plan at any time. Changes take effect at the next
                billing cycle unless otherwise agreed. Upgrading may incur
                additional fees, while downgrading may result in a loss of
                features.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Termination: </b>Either party can terminate the subscription
                at any time with written notice. If the customer terminates,
                access to services continues until the end of the current
                billing period. If iNextLabs terminates for reasons other than a
                breach of terms, the customer is entitled to a pro-rata refund.{" "}
              </p>
            </li>
            <li className="para">
              <p>
                <b>Early Termination: </b>Early termination fees may apply as
                outlined in the subscription plan to compensate iNextLabs for
                the loss of anticipated revenue.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Changes to Subscription Plans: </b>iNextLabs reserves the
                right to modify its subscription plans. Customers will be
                notified of any material changes at least 30 days in advance.
              </p>
            </li>
          </ol>
          <br />
          <h3 className="paragraphTitle">5.Service Level Agreement (SLA)</h3>
          <p className="para">
            iNextLabs is committed to providing reliable and high-quality
            services. This SLA outlines performance standards for its
            generative-AI solutions.
          </p>
          <ol>
            <li className="para">
              <p>
                <b>Uptime Commitment: </b> iNextLabs guarantees a minimum of
                99.5% uptime availability, excluding scheduled maintenance,
                emergency maintenance, and events outside iNextLabs' control.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Uptime Commitment:</b>
              </p>
              <ul>
                <li>
                  <p>
                    <b>Standard Support: </b> Response within 8 business hours
                    for critical issues, 24 business hours for non-critical
                    issues.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Premium Support:</b> Response within 4 business hours for
                    critical issues, 12 business hours for non-critical issues.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Enterprise Support: </b>Response within 1 business hour
                    for critical issues, 4 business hours for non-critical
                    issues.
                  </p>
                </li>
              </ul>
            </li>
            <li className="para">
              <p>
                <b>Incident Management: </b>iNextLabs will monitor services to
                detect and respond to incidents, providing regular updates to
                affected customers until resolution.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Maintenance: </b>Scheduled maintenance will be announced 48
                hours in advance and typically occurs during off-peak hours.
                Emergency maintenance may occur without notice if necessary.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Exclusions: </b>The SLA does not cover issues caused by
                factors outside of iNextLabs' control, customer misuse,
                third-party services, or scheduled maintenance.
              </p>
            </li>
          </ol>
          <br />
          <h3 className="paragraphTitle">6.Backup Availability</h3>
          <p className="para">
            Backups of customer data are available upon request. iNextLabs will
            provide backups in a timely manner, subject to the terms of the
            support agreement. iNextLabs does not guarantee backup availability
            beyond the specified retention period and is not liable for data
            loss outside this period.
          </p>

          <p className="para">
            For any questions or concerns, please contact us at{" "}
            <a href="mailto:accounts@inextlabs.com">accounts@inextlabs.com</a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfService;
